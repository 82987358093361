import {useEffect, useState} from "react";
import moment from "moment";
import AddContent from "./AddContent";
import AddPhotos from "./AddPhotos";
import './addActivity.scss';

const getDefaultYear = () => {
    if (moment().month() >= 8) {
        return `${moment().year()}${moment().year()+1}`;
    } else {
        return `${moment().year()-1}${moment().year()}`;
    }
};

const AddActivity = ({save, cancel}) => {
    const [content, setContent] = useState([]);
    const [activity, setActivity] = useState({
        id: Date.now(),
        title: '',
        year: getDefaultYear(),
        styles: 'no-top-padding justify',
        date: moment().format('YYYY-MM-DD'),
        content: []
    });
    const [gallery, setGallery] = useState({
        type: 'gallery',
        id: `gal${activity.id}`,
        folder: `/zdjecia/${activity.year}/${activity.id}/`,
        items: []
    });

    useEffect(() => {
        const copy = {...activity}
        copy.content = [...content];
        setActivity(copy);
    }, [content]);

    const setTitle = (value) => {
        const copy = {...activity};
        copy.title = value;
        setActivity(copy);
    };

    const setYear = (el) => {
        const copy = {...activity};
        copy.year = el.target.value;
        setActivity(copy);
    };

    const addGallery = () => {
        const copy = {...activity};
        copy.content.push(gallery);
        setActivity(copy);
    }

    const allowedList = {
        text: true,
        paragraph: true
    };

    const localSave = () => {
        addGallery();
        save(activity);
    }

    return (
        <div className="addActivity">
            <div className="head">Dodawanie artykułu do 'Z życia przedszkolaka'</div>
            <div>
                <span className={'title'}>Tytuł:</span>
                <input size="100" type="text" onChange={(el) => setTitle(el.target.value)}></input>
            </div>
            <div>
                Rok: {`${activity.year.slice(0,4)}/${activity.year.slice(4)}`}
                {/*<select name="year" value={activity.year} onChange={(el) => setYear(el)}>
                    <option value={`${moment().year()-1}${moment().year()}`}>{moment().year() -1}/{moment().year()}</option>
                    <option value={`${moment().year()}${moment().year()+1}`}>{moment().year()}/{moment().year()+1}</option>
                </select>*/}
            </div>
            <div>
                Dodaj treść:
                <AddContent content={content} setContent={setContent} availableItems={allowedList}></AddContent>
            </div>
            <div>
                Dodaj zdjęcia:
                <AddPhotos gallery={gallery} setGallery={setGallery} id={activity.id} year={activity.year}></AddPhotos>
            </div>
            <button onClick={localSave}>Zapisz</button>
            <button onClick={cancel.bind(this)}>Anuluj</button>
        </div>
    );
}

export default AddActivity;