function AddParagraph({item, setItem, index}) {

    const updateText = (value) => {
        const newVal = value.replace('\n', ' ');
        console.log(newVal);
        setItem(index, newVal);
    }
    return (<div>
        <div>
            {`Paragraf (${index+1})`}
        </div>

        {/*<input type="text" onChange={(ev) => updateParagraph(ev.target.value)} value={item.content}></input>*/}
        <textarea rows="10" cols="80" onChange={(ev) => updateText(ev.target.value)} value={item.content}></textarea>
    </div>);
}

export default AddParagraph