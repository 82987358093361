function AddText({item, setItem, index}) {

    const updateText = (value) => {
        const newVal = value.split(/\n/);
        setItem(index, newVal)
    }

    const getValue = () => {
        let text = '';
        item.content.forEach((el, index) => {
            text += el;
            if (index !== item.content.length - 1) {
                text += '\n';
            }
        });
        return text;
    }

    return (<div>
        <div>
        {`Tekst (${index+1})`}
        </div>
        <textarea rows="10" cols="80" onChange={(ev) => updateText(ev.target.value)} value={getValue()}></textarea>
    </div>);
}

export default AddText