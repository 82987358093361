import {useState} from "react";
import AddActivity from "./AddActivity";
import './editActivities.scss';

function EditActivities({data, setData}) {
    const [isAction, setIsAction] = useState('');

    const addActivity = () => {
        setIsAction(true);
    }

    const cancel = () => {
        setIsAction(false);
    }

    const saveActivity = (newActivity) => {
        const copy = {...data};
        copy.activity.content.push(newActivity);
        setData(copy);
        setIsAction(false);
    }

    const drawAddingPanel = () => {
        if (isAction) {
            return (<AddActivity save={saveActivity} cancel={cancel}></AddActivity>);
        }
    }

    const drawActions = () => {
        if (!isAction) {
            return (<button className="actionBtn" onClick={addActivity}>Dodaj nowy wpis do "Z życia przedszkolaka"</button>)
        }
    }

    return (<div className="editActivities">
        {data.activity.currentYear === data.archive.data[0].year ?
            <h1>Uwaga, w archiwum i w "Z życia przedszkolaka" są te same artykuły - przejdź do "Akcje archiwum" żeby zacząć nowy rok</h1> : ''}
        {drawActions()}
        {drawAddingPanel()}
    </div>);
}

export default EditActivities;