import AddParagraph from "./AddParagraph";
import AddText from "./AddText";

let idNo = 0;

function AddContent({content, setContent, availableItems, style}) {

    const generateCurrentlyAddedItems = () => {
        const result = [];
        content.forEach((item, index) => {
            let form = ('');
            switch(item.type) {
                case 'paragraph': {
                    form = (<AddParagraph key={item.id} index={index} item={item} setItem={setItem}></AddParagraph>);
                    break;
                }
                case 'text': {
                    form = (<AddText key={item.id} index={index} item={item} setItem={setItem}></AddText>);
                    break;
                }
                default:
                    break;
            }
           result.push(form);
        });
        return result;
    }

    const setItem = (index, newValue) => {
        const copy = [...content];
        copy[index].content=newValue
        setContent(copy);
    }

    const addParagraph = () => {
        const copy = [...content];
        copy.push({
            type: 'paragraph',
            styles: style ? style : '',
            id: Date.now(),
            content: []
        });
        setContent(copy);
    }

    const addText = () => {
        const copy = [...content];
        copy.push({
            type: 'text',
            id: Date.now(),
            styles: style ? style : '',
            content: []
        });
        setContent(copy);
    }

    const addSection = () => {
        const copy = [...content];
        copy.push({
            type: 'section',
            id: Date.now(),
            styles: 'center',
            content: []
        });
        setContent(copy);
    }

    const addBullets = () => {
        const copy = [...content];
        copy.push({
            type: 'bullets',
            id: Date.now(),
            styles: '',
            content: []
        });
        setContent(copy);
    }

    const addGalery = () => {
        const copy = [...content];
        copy.push({
            type: 'gallery',
            id: Date.now(),
            styles: '',
            folder: '',
            items: []
        });
        setContent(copy);
    }

    const addFile = () => {
        const copy = [...content];
        copy.push({
            type: 'document',
            id: Date.now(),
            fileName: '',
            name: ''
        });
        setContent(copy);
    }

    const generateAddItems = () => {
        return (
            <div>
                {availableItems.paragraph ? <button onClick={addParagraph}>Dodaj nowy paragraf</button> : ''}
                {availableItems.text ? <button onClick={addText}>Dodaj nowy tekst</button> : ''}
                {availableItems.section ? <button onClick={addSection}>Dodaj pod-nagłówek</button>: ''}
                {availableItems.bullets ? <button onClick={addBullets}>Nowa lista wypunktowana</button>: ''}
                {availableItems.gallery ? <button onClick={addGalery}>Dodaj galerie</button> : ''}
                {availableItems.documents ? <button onClick={addFile}>Dodaj plik</button>: ''}
            </div>
        )
    };

    return (
        <div>
            {generateCurrentlyAddedItems()}
            {generateAddItems()}
        </div>
        )
}

export default AddContent